.root {
	position: relative;
	background: var(--materia-accent-border-color, var(--token-color-border-primary));
	border-radius: var(--hdsplus-spacing-04);
	padding: var(--hdsplus-spacing-05);
	isolation: isolate;
	display: flex;
	gap: var(--hdsplus-spacing-03);

	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		inset: var(--materia-accent-border-width, 1px);
		background-color: var(--token-color-surface-primary);
		border-radius: calc(var(--hdsplus-spacing-04) - var(--materia-accent-border-width, 1px));
	}
}

.icon {
	flex-shrink: 0;
	flex-grow: 0;
	margin-top: var(--hdsplus-spacing-01);
	fill: url(#icon-gradient);
}

.gradientStart {
	stop-color: var(--materia-accent-icon-color, var(--token-color-foreground-faint));
}

.gradientEnd {
	stop-color: var(--materia-accent-icon-gradient-end, var(--materia-accent-icon-color, var(--token-color-foreground-faint)));
}